.ProductPage {
    background-color: #f0f1f2;
}

.thunder-footer {
    padding-top: 10px !important;
    margin-top: 0px !important;
    height: 100%;
}

/*页面跳转定点*/
.education-suit-container > #steam-suite {
    position: relative;
    top: -100px;
    display: block;
    height: 2px;
    overflow: hidden;
}

.education-expand-container > #expand {
    position: relative;
    top: -100px;
    display: block;
    height: 2px;
    overflow: hidden;
}

.education-parts-container > #spare-parts {
    position: relative;
    top: -100px;
    display: block;
    height: 2px;
    overflow: hidden;
}

/*#steam-suite,#expand,#spare-parts {*/
/*position: relative;*/
/*top: -100px;*/
/*display: block;*/
/*height: 2px;*/
/*overflow: hidden;*/
/*}*/
/*页面跳转定点*/

/*缩略图展示*/
.modal-dialog {
    width: 1002px;
}

.modal-dialog > .modal-content {
    border-radius: 0;
}

.modal-body {
    border-bottom: none;
    padding: 0 auto;
}

.modal-body > img {
    width: 100%;
    height: 800px;
}

.headerpage {
    z-index: 99;
}

.thunder-footer {
    margin-top: 0;
}

/*页面顶部样式*/
.detail-nav {
    width: 100%;
    height: 300px;
    margin: 0 auto 60px;
    background-repeat: no-repeat;
    background-image: url(/uploads/img/product-detail-head.png);
    background-color: #ffdcb5;
    background-size: auto 300px;
    background-position-x: 80%;
}

.detail-nav > div {
    padding-top: 100px;
}

.detail-nav p:nth-child(1) {
    font-size: 26px;
    font-weight: 500;
}

.detail-nav p:nth-child(2) {
    font-size: 16px;
    line-height: 16px;
    color: #666666;
}

/*STEAM教育套件*/
.education-suit-container {
    margin: 0 auto 0;
    padding: 0 0 60px;

}
.product-suite {
    background: #ffffff no-repeat;
    cursor: pointer;
    display: block;
    transition: all 0.3s ease-in;
}

.education-suit-container > p, .education-expand-container > p, .education-parts-container > p {
    text-align: center;
    font-size: 36px;
    height: 65px;
    padding-top: 0px;
    font-weight: 700;
}

.education-suit-container > p > img, .education-expand-container > p > img, .education-parts-container > p > img {
    width: 36px;
    height: 36px;
    margin-top: -7px;
    margin-right: 16px;
}

.product-suite {
    margin: 0 auto 20px;
    width: 1200px;
    height: 340px;
    padding-left: 100px;
    background-position:right;
}
.parts-content {
    height: 580px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 580px;
    padding: 40px 60px 0;

}

.product-suite:nth-of-type(2) {
    background-image: url(/uploads/img/suite-01.png);
}

.product-suite:nth-of-type(3) {
    background-image: url(/uploads/img/suite-02.png);
}

.product-suite:nth-of-type(4) {
    background-image: url(/uploads/img/suite-03.png);
}
.product-suite:nth-of-type(5) {
    background-image: url(/images/pp83004.jpeg);
    -ms-background-position-x: 90%;
    background-position-x: 90%;
}

.expand-content {
    padding: 65px 100px;
}


.pb-part6{
    display: flex;
    justify-content: center;
}
.flex1{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.product-suite > .content {
    width: 436px;
}

.expand-content > .title {
    font-size: 32px;
    padding-top: 30px;
    font-weight: 600;
    color: #333333;
}

.parts-content >.flex1> .title {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
}

.product-suite > .title {
    font-size: 32px;
    padding-top: 64px;
    font-weight: 600;
    color: #333333;
}

.product-suite > .subtitle, .parts-content > .subtitle, .expand-content > .subtitle {
    font-size: 22px;
    font-weight: 600;
    color: #333333;
}

.product-suite > .content, .parts-content > .flex1>.content, .expand-content > .content {
    font-size: 16px;
    line-height: 26px;
    margin-top: 16px;
    color: #666666;
    height: 40px;
}

.product-suite .btn, .parts-content .btn, .expand-content .btn {
    width: 150px;
    height: 37px;
    border-radius: 0;
    background-color: #ff9b00;
    color: #ffffff;
    margin-top: 16px;
}

.parts-img-content1{
    background: url(/uploads/img/suite-04.png) left no-repeat;
    background-size: auto 100%;
}
.parts-img-content2{
    background: url(/uploads/img/suite-05.png) left no-repeat;
    background-size: auto 100%;
}

.parts-img-content3{
    background: url(/images/pp61004.png) left no-repeat;
    background-size: auto 100%;
}
.parts-img-content4{
    background: url(/images/pp_tc001.png) left no-repeat;
    background-size: auto 100%;
}

.parts-img-content5{
    background: url(/uploads/img/suite-06.png) left no-repeat;
    background-size: auto 100%;
}

.parts-img-content6{
    background: url(/uploads/img/suite-07.png) left no-repeat;
    background-size: 100% auto;
}


.product-suite:hover, .parts-content:hover, .expand-content:hover {
    box-shadow: 0px 0px 8px #cccccc;
    transition: all 0.4s ease-in;
}

@media screen and (max-device-width: 960px){
    .product-suite {
        margin: 0 auto 20px;
        width: 82%;
        height: 340px;
        padding-left: 100px;
        background-position:right;
    }
    .parts-content {
        height: 500px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        width: 40%;
        padding: 40px 60px 0;

    }
    .product-suite:nth-of-type(2) {
        background-image: url(/uploads/img/suite-01.png);
        background-position-x: 180%;
    }

    .product-suite:nth-of-type(3) {
        background-image: url(/uploads/img/suite-02.png);
        background-position-x: 200%;
    }

    .product-suite:nth-of-type(4) {
        background-image: url(/uploads/img/suite-03.png);
        background-position-x: 200%;
    }
    .product-suite:nth-of-type(5) {
        background-image: url(/images/pp83004.jpeg);
        background-position-x: 120%;
    }
    .parts-img-content6{
        background: url(/uploads/img/suite-07.png) left no-repeat;
        background-size: 270px auto;
    }
    .parts-img-content4{
        background: url(/images/pp_tc001.png) left no-repeat;
        background-size: 270px auto;
    }
}

/*按钮修改*/
.btn {
    position: relative;
    border: none;
    font-size: 18px;
}

.btn:focus {
    color: #ffffff;
}

.btn:after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;
    content: "";
}

.btn:hover {
    color: #ffffff;
}

.btn:hover:after {
    opacity: 0.15;
}

.btn:active:after {
    opacity: 0.3;
}

.education-expand-container > .col-lg-6:first-of-type {
    padding-left: 0px;
}

.education-expand-container > .col-lg-6:last-of-type {
    padding-right: 0px;
}

.modal.fade .modal-dialog {
    transform: scale(.5) translate(0, 0);
}

.modal.in .modal-dialog {
    transform: scale(1) translate(0, 0);
}
