@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0) scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0) scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0) scaleX(0.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0) scaleX(0.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

@keyframes nodeInserted2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tinyPage {
  position: relative;
}
.tinyDetail {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  .bg1 {
    height: 100vh;
    width: 100%;
    background: url(/images/tiny/bg1.jpg) center no-repeat;
    background-size: cover;
  }
  .bg2 {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: url(/images/tiny/bg2.jpg) center no-repeat;
    background-size: cover;
    top: 0;
  }
  .tinyTitle {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    font-size: 30px;
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%, 0);
    h3 {
      font-size: 48px;
      color: #fff;
    }
  }
}
@media screen and (max-device-width: 800px) {
  .tinyDetail {
    height: 500px;
    width: 100%;
    overflow: hidden;
    position: relative;
    .bg1 {
      height: 500px;
      width: 100%;
      background: url(/images/tiny/bg1.jpg) center no-repeat;
      background-size: cover;
      background-origin: content-box;
    }
    .bg2 {
      position: absolute;
      height: 500px;
      width: 100%;
      background: url(/images/tiny/bg2.jpg) center no-repeat;
      background-size: cover;
      top: 0;
    }
    .tinyTitle {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      font-size: 30px;
      position: absolute;
      bottom: 120px;
      left: 50%;
      transform: translate(-50%, 0);
      h3 {
        font-size: 48px;
        color: #fff;
      }
    }
  }
}

.relativeP {
  position: relative;
  margin-top: 160px;
  width: 100%;
}
.fixedP {
  position: fixed;
  margin-top: 0px;
  width: 100%;
  top: 80px;
  z-index: 1;
}
.show {
  position: absolute;
  top: 9rem;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: slide-in 2s forwards;
  z-index: 2;
}
.hid {
  animation: slide-out 3s forwards;
  opacity: 1;
  z-index: 2;
}
.setOpacity {
  animation: move 1s ease;
  opacity: 0;
}
.opacity {
  opacity: 1;
}
@keyframes slide-in {
  100% {
    top: 2rem;
    opacity: 1;
  }
}
@keyframes slide-out {
  100% {
    top: 4rem;
    // opacity: 0;
  }
}
@keyframes move {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tinyIcon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 22px;
  color: #1a1a1a;
  width: 100%;
  background-attachment: fixed;
  position: relative;
  z-index: 9;
  padding-top: 50px;
  .pro {
    width: 600px;
    text-align: center;
    opacity: 0.7;
    font-size: 18px;
    font-weight: 400;
  }
  .tinySpe {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    width: 100%;
    margin-top: 0.9rem;

    .classify {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      width: 33.3%;
      margin-bottom: 0.6rem;
      img {
        height: 0.84rem;
        width: 0.84rem;
        margin-bottom: 0.3rem;
      }
      p {
        font-size: 0.28rem;
        color: #1a1a1a;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        line-height: 0.42rem;
      }
    }
  }
}
.tinyVideo {
  position: relative;
  video {
    width: 100%;
    object-fit: contain;
  }
  .playBtn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0.72rem;
    width: 0.72rem;
    // z-index: 999;
    cursor: pointer;
    // &:hover{
    //   opacity: 0.3;
    // }
  }
  .playBg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 0.72rem;
    width: 0.72rem;
    cursor: pointer;
    z-index: 0;
    &:hover {
      .bgColor {
        opacity: 0.3;
      }
    }
  }
}
.tinyModule {
  font-size: 34px;
  padding: 1rem 1.9rem 1.2rem 2.4rem;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  .modules {
    display: flex;
    align-items: center;
    margin-top: 0.47rem;
    justify-content: center;
    .moduleLeft {
      display: flex;
      flex-flow: column wrap;
      font-size: 22px;
      width: 41%;
      margin-right: 1rem;
      .moduleItem {
        position: relative;
        margin-bottom: 0.18rem;
        cursor: pointer;
        font-weight: 400;
        color: #202020;
        .des {
          display: none;
          margin: 0;
          margin-top: 0.08rem;
        }
        .line {
          position: absolute;
          left: -0.18rem;
          width: 0.02rem;
          border-left: 0.02rem solid #d8d8d8;
          height: 0.6rem;
          top: 0;
        }
      }
      .desActive {
        display: block;
        font-size: 14px;
        color: #565757;
        margin: 0;
        margin-top: 0.14rem;
        transition: all 0.5s;
      }
      .lineActive {
        position: absolute;
        left: -0.18rem;
        width: 0.02rem;
        border-left: 0.02rem solid #1a1a1a;
        height: 1.3rem;
        top: 0;
        transition: all 0.5s;
      }
      .lineActive2 {
        position: absolute;
        left: -0.18rem;
        width: 0.02rem;
        border-left: 0.02rem solid #1a1a1a;
        height: 1.2rem;
        top: 0;
        transition: all 0.5s;
      }
    }
    .moduleRight {
      display: flex;
      flex: 1;
      width: 50%;
      height: 5.2rem;
      position: relative;
      overflow: hidden;
      img {
        transition: all 0.6s;
        animation: nodeInserted2 0.5s ease-in;
        animation-duration: 0.5s;
        animation-timing-function: ease-in;
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: nodeInserted2;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        transition: all 0.5s linear;
      }
    }
  }
}
.tinyActivity {
  font-size: 34px;
  width: 100%;
  text-align: center;
  .activity {
    width: 100%;
    height: 90vh;
    margin-top: 0.84rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    video {
      width: 100%;
      height: 90vh;
      object-fit: cover;
      transition: all 0.5s linear;
    }
    .mask{
      width: 100%;
      height: 90vh;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }
    .activityItem {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      color: #fff;
      justify-content: flex-end;
      padding-bottom: 0.72rem;
      box-sizing: border-box;
      width: 100%;
      height: 90vh;
      border-right: 1px solid rgba(255, 255, 255,0.1);
      cursor: pointer;
      z-index: 6;
      &:hover {
        p {
          opacity: 1;
        }
      }
      img {
        height: 0.54rem;
        width: 0.54rem;
        margin-bottom: 0.26rem;
      }
      p {
        font-size: 16px;
        opacity: 0.5;
      }
    }
  }
}

@media screen and (max-device-width: 800px) {
  .tinyActivity {
    font-size: 34px;
    width: 100%;
    text-align: center;
    .activity {
      width: 100%;
      height: 500px;
      margin-top: 0.84rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      video {
        width: 100%;
        height: 500px;
        object-fit: cover;
        transition: all 0.5s linear;
      }
      .activityItem {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        color: #ffffff;
        justify-content: flex-end;
        padding-bottom: 0.72rem;
        box-sizing: border-box;
        width: 100%;
        height: 500px;
        border-right: 1px solid rgb(154, 132, 127);
        cursor: pointer;
        z-index: 6;
        &:hover {
          p {
            opacity: 1;
          }
        }
        img {
          height: 0.54rem;
          width: 0.54rem;
          margin-bottom: 0.26rem;
        }
        p {
          font-size: 16px;
          opacity: 0.5;
        }
      }
    }
  }
}
.tinyCode {
  font-size: 34px;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
  padding: 0 0.48rem;
  box-sizing: border-box;
  font-weight: 500;
  .produce {
    margin-top: 0.36rem;
    text-align: center;
    font-size: 16px;
    line-height: 0.36rem;
    font-weight: 400;
    opacity: 0.7;
  }
  img {
    margin-top: 0.48rem;
    width: 100%;
    box-sizing: border-box;
    height: 6rem;
    border-radius: 0.12rem;
    object-fit: contain;
  }
}

.tebot1 {
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    width: 1200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    height: 800px;
    object-fit: cover;
    display: inline-block;
    vertical-align: baseline;
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 800px;
    position: relative;
    z-index: 9;
    .swiper-control-box {
      width: 384px;
      display: flex;
      align-items: center;
      z-index: 0;
    }
    .control-module {
      padding-left: 16px;
      position: relative;
      display: inline-block;
      border-left: 1px solid #525252;
      .control-item {
        padding-bottom: 8px;
        position: relative;
      }
      .item-toggle {
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        line-height: 32px;
      }
      .control-item-desc {
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.65);
        height: 0;
        opacity: 0;
        transition: all 0.5s;
      }
      .hide-desc {
        display: none;
        line-height: 24px;
        font-size: 14px;
        padding-bottom: 16px;
      }
    }
    ul {
      list-style: none;
      vertical-align: baseline;
    }
    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
    ul,
    li {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-style: inherit;
      font-family: inherit;
    }
  }
}
