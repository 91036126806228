.Parts {
    background-color: #f0f1f2;
}

.thunder-footer {
    padding-top: 10px !important;
    margin-top: 0px !important;
    height: 100%!important;
}


.Parts [class*="col-"]{
    padding-left: 10px;
    padding-right: 10px;
}

.col-xs-prec-25,.col-sm-prec-25,.col-md-prec-25,.col-lg-prec-25 {
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.col-xs-prec-25 {
    width: 20%;
    float: left;
}




/*.thunder-col-per-20 {*/
    /*width: 20%;*/
    /*float: left;*/
    /*position: relative;*/
    /*min-height: 1px;*/
/*}*/
/*.thunder-col-per-20>* {*/
    /*margin: 0 auto;*/
/*}*/

.thu-partsshow-nav {
    /*width: 100%;*/
    height: 300px;
    margin: 0 auto;
    background : #e0dcd7 url(/uploads/img/thu-partsshow-01.png) no-repeat right center;
    background-size: auto 1070px;
}
.thu-partsshow-nav>.thu-partsshow-nav-title {
    /*width: 100%;*/
    margin: 0 auto;
}
.thu-partsshow-nav>.thu-partsshow-nav-title>p {
    font-size: 40px;
}



.Parts .row>* {
    margin-right: 20px;
    margin-bottom: 5px;
}
.thu-mechanical-parts {
    background-color: #ffffff;
    margin: 60px auto 0;
    padding: 25px;
}

.thu-mechanical-parts {
    font-size: 24px;
    /*font-weight: 700;*/
}
.thu-mechanical-parts> div >p {
    margin-left: 10px;
    font-size: 20px;
}
.thu-mechanical-parts>img {
    width: 35px;
    margin-right: 15px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.thu-mechanical-parts>div {
    width: 100%;
    overflow: hidden;
}
.thu-show-grid {
    height: 236px;
}
.thu-show-grid>div {
    background: #f7f9f9 no-repeat;
    width: 172px;
    height: 172px;
    margin: 0 auto;
}
.thu-show-grid>p {
    text-align: center;
    font-size: 16px;
    margin-top: 14px;
}

@media (min-width: 768px) {
    .col-sm-prec-25 {
        width: 20%;
        float: left;
    }
    .thu-partsshow-nav-title>p {
        padding-top: 150px;
    }
}
@media (max-width: 768px) {
    .thu-partsshow-nav {
        width: 100%;
        /*height: 200px;*/
        zoom: 0.5;
    }
    .thu-partsshow-nav-title>p {
        padding-top: 190px;
        padding-left: 20px;
    }
    .thu-mechanical-parts {
        width: 100%;
    }
    .thu-show-grid {
        zoom: 0.8;
    }
}

@media (min-width: 970px) and (max-width: 1200px){
    .col-md-prec-25 {
        width: 20%;
        float: left;
    }
    .thu-partsshow-nav-title {
        width: 1000px;
    }
    .thu-mechanical-parts {
        width: 1000px;
    }
}
@media (max-width: 970px) and (min-width: 768px){
    .thu-partsshow-nav {
        zoom: 0.75;
    }
    .thu-mechanical-parts {
        width: 800px;
    }
    .thu-partsshow-nav-title {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .thu-partsshow-nav>.thu-partsshow-nav-title {
        width: 1200px;
    }
    .thu-mechanical-parts {
        width: 1200px;
    }
    .col-lg-prec-25 {
        width: 20px;
        float: left;
    }
}
