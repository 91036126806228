.parts_container {
  background: #f0f1f2;

  .content {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    padding-top: 60px;
    padding-bottom: 20px;

    .parts-list {
      .ant-list-item {
        margin-bottom: 20px;
      }
    }

    .card_item {
      overflow: hidden;
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 0px;
      width: 230px;
      height: 230px;
      .card_item0 {
        width: 100%;
        border-radius: 10px;
        background-color: rgba($color: #000000, $alpha: 0.5);
        transition: top 0.3s;
        position: absolute;
        height: 100%;
        top: -100%;
        text-align: center;
        cursor: pointer;
        p {
          color: white;
        }
      }

      .card_item1 {
        border-radius: 10px;
        height: 100%;
        .sprite1 {
          // background-image: url(/uploads/img/0808方梁类/0808.png);
          display: flex;
          background-position: 0 0;
          height: 172px;
          width: 172px;
          .parts-avatar {
            width: 172px;
            height: 172px;
            img {
              object-fit: contain;
            }
          }
          .imageTitle {
            position: absolute;
            bottom: 0;
            color: #606060;
          }
        }
      }
    }

    div.card_item:hover .card_item0 {
      top: 0;
    }
  }
}

.ant-modal-header {
  background-color: #f0f1f2 !important;
}

.parts-table-clear {
  border-radius: 4px;
  border: 2px solid #979797;
  padding: 5px;
  cursor: pointer;
  margin-right: 30px;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    font-size: 16px;
    margin-left: 5px;
  }
}

.parts-table-eidt {
  border-radius: 4px;
  background-color: #fe9d00;
  padding: 5px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  span {
    font-size: 16px;
    color: white;
    margin-left: 5px;
  }
}

.parts-table-footer {
  width: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  .down-btn {
    width: 112px;
    height: 40px;
    background: #fe9d00;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    &:hover,
    &:active,
    &:focus {
      background-color: #fe9d00a6;
    }
    span {
      color: white;
      font-size: 14px;
    }
  }
}

.shopBadge {
  span > p {
    color: white;
    font-size: 16px;
  }
}

.addDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  // margin-top: 12px;
  margin-right: 0px;
  border-radius: 10px;
  width: 230px;
  span {
    color: #606060;
    font-size: 16px;
  }
  .plus-btn {
    background-color: #f8f8f8;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 40px;
    &:hover {
      background-color: #ffb640;
      span {
        color: white;
      }
    }
  }

  .addbtn {
    background-color: #ffb640 !important;
    border-radius: 10px;
    border-width: 0;
    span {
      color: white;
      font-size: 18px;
    }
  }
}

.table-add-btn {
  margin-top: 0px;
  width: 150px;
  span {
    font-size: 14px;
  }
}

.table-container {
  .ant-table-body {
    overflow-y: auto !important;
  }
}

.table-header-ok {
  span {
    font-size: 14px;
    color: white;
  }
}
.table-header-cancel {
  span {
    font-size: 14px;
  }
}
.btn-white {
  span {
    color: white;
    font-size: 16px;
  }
}
.ant-check {
  .ant-checkbox-inner {
    border-color: #ffb640 !important;
    background: #ffb640 !important;
  }
}
.parts-banner {
  position: relative;
  .parts-banner-text {
    position: absolute;
    color: white;
    font-family: "思源黑体 CN Bold Bold";
    font-weight: bold;
    font-size: 42px;
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 5px;
  }
}

.search-tree-select {
  .ant-select-arrow {
    width: 18px;
    margin-top: -8px;
  }
}

.feedback-input:focus {
  border-color: #fe9d00 !important;
  box-shadow: none !important;
}
.feedback-input:hover {
  border-color: #fe9d00 !important;
  box-shadow: none !important;
}

.feedback-ca > input:focus {
  border-color: #fe9d00 !important;
  box-shadow: none !important;
}
.feedback-ca > input:hover {
  border-color: #fe9d00 !important;
  box-shadow: none !important;
}
.parts-menu {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f8f8f8;
    color: black;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #ffb640;
  }
  .ant-menu-item:hover,
  .ant-menu-item-active {
    color: #ffb640;
  }
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline
    > .ant-menu-submenu-title:hover
    .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, #ffb640, #ffb640);
  }
}

.shop-row {
  span {
    font-size: 14px;
  }
}
.odd-row {
  background: rgba($color: #000000, $alpha: 0.03);
}

.shop-table-header {
  .ant-table-cell {
    padding: 10px 16px;
  }
}
