
.tc_1 {
    background: black;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tc_1 > img {
    width: 50%;
}

.tc_1_hand {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 180px;
}

.tc_1_hand > div {
    flex: 1;
}

.tc_1_hand > img {
    width: auto;
}

.tc_1_hand p {
    font-size: 18px;
    font-weight: 500;
    color: rgba(119, 119, 119, 1);
    text-align: center;
}

.tc_3 {
    background: black;
    width: 100%;
    margin-top: -80px;
}

.tc_3 h2 {
    margin-top: 30%;
    color: #FFFFFF;
    font-size: 36px;
}

.tc_3 p {
    color: #FFFFFF;
    font-size: 18px;
}

.tc_4 {
    background: #F6F6F6;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tc_4 > img {
    width: 60%;
}

.tc_5 {
    display: flex;
    justify-content: center;
}

.tc_5 .thunder-title {
    position: absolute;
    top: 20px;
}

.tc_5 .thunder-title h2 {
    color: #FFFFFF;
}

.tc_5 .thunder-title p {
    color: #FFFFFF;
}

.tc_6 img {
    width: 50%;
}

.tc_7 {
    background: #F6F6F6;
    padding-top: 20px;
}

.tc_7_txt {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 0;
}
.tc_b{
    background: url("/images/tc001/tc_img_6.png") top center no-repeat;
    background-size: 100% 100%;
    padding-top: 2rem;
    margin-bottom: 2rem;
}
.tc_7_txt p {
    text-align: left;
    font-size:18px;
    color: #333333;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.btn_tc_down {
    background: #F3981B;
    height: 50px;
    width: 160px;
    border-radius: 25px;
    margin-top: 30px;
    cursor: pointer;
}

.btn_tc_down:hover{
    opacity: 0.7;
}

.btn_tc_down span {
    color: #FFFFFF;
}

.ct_8_c {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
}
.ct_8_c img{
    width: 50%;
}
.ct_8_c>div{
    margin-bottom: 80px;
}

.ct_8_b{
    background:#F6F6F6;
    padding-top: 40px;
    padding-bottom: 40px;
}
.ct_8_b p{
    color: #333333;
    font-size:18px;
}
