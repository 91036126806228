#banner .swiper-pagination-bullet {
  display: inline-block;
  width: 16px;
  height: 16px;
  opacity: 1;
  border-radius: 10px;
  background:#686868;
  margin: 0 3px;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
}
#banner .swiper-pagination-bullet-active {
  background: #fdd000;
  width: 48px;
}
