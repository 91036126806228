
.top_content {
    display: flex;
    background: url(/images/home_bg_top.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    height: 799px;
    align-items: flex-end;
    padding-bottom: 47px;
    padding-left: 24%;
}

.top_content div > p {
    width: 460px;
    font-size: 16px;
    color: #666666;
    font-weight: bold;
    line-height: 41px;
    text-align: justify;
}

.btn_video {
    width: 215px;
    height: 66px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    border: 1px solid rgba(217, 217, 217, 0.5);
    box-shadow: 2px 3px 3px 0px rgba(201, 201, 201, 0.75);
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_video > img {
    width: 37px;
}

.btn_video > span {
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    margin-left: 15px;
}

.paddingT50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.s-title46 {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #464646;
    margin-top: 2rem;
}

.p-txt46 {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #464646;
}

.card-f {
    height: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem;
}

.module {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F6F6F6;
    padding-top: 50px;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
}

.module_h {
    font-size: 36px;
    font-weight: 800;
    color: #333333;
}

.module_title {
    font-size: 24px;
    color: #333333;
    margin-top: 31px;
    margin-bottom: 31px;
}

.module_content {
    font-size: 18px;
    color: #666666;
    width: 70%;
    text-align: justify;
}

.module_img {
    margin-top: 1rem;
    width: 60%;
}

.float_left_check {
    width: 20rem;
    height: 6rem;
    background: rgba(255, 156, 0, 0.6);
    border-radius: 3rem 0 0 3rem;
    display: flex;
    align-items: center;
}

.float_right_check {
    width: 20rem;
    height: 6rem;
    background: rgba(255, 156, 0, 0.6);
    border-radius: 0 3rem 3rem 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.float_1 {
    right: 0;
    top: 25rem;
    margin-right: -11rem;
}

.float_2 {
    right: 0;
    top: 45rem;
    margin-right: -11rem;
}

.float_3 {
    left: 0;
    top: 25rem;
    margin-left: -11rem;
}

.float_4 {
    left: 0;
    top: 45rem;
    margin-left: -11rem;
}

.ani-rent{
    display: flex;
    align-items: center;
    height: 10rem;
    z-index: 99;
}

.gif_active{
    margin-right: 0;
    margin-left: 0;
}
.gif_active>div{
    background: rgba(255, 156, 0, 1);
}

.ani-rent:hover {
    margin-right: 0;
    margin-left: 0;
}

.ani-rent:hover > div {
    background: rgba(255, 156, 0, 1);
}

.car_m_1 {
    width: 40%;
}

.car_m_2 {
    width: 40%;
}

.car_m_3 {
    width: 40%;
}

.car_m_4 {
    width: 40%;
}

.space {
    width: 20px;
}

.rent_lb {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
}

.module2 {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 60px;
}

.module2 > img {
    width: 60%;
}

.module3 {
    background: #F6F6F6;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.grid_steam {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid_steam > span {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
}

.module4 {
    width: 100%;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.module4 > p, span {
    font-size: 18px;
    color: rgba(102, 102, 102, 1);
}

.module4 > img {
    width: 100%;
}

.module4 > div {
    display: flex;
    flex: 1;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.card_4 {
    width: 320px;
    height: 370px;
    background: rgba(244, 243, 244, 1);
    border-radius: 2px 2px 2px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.card_4_item_1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

}

.card_4_item_2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.card_title {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-top: 19px;
    margin-bottom: 19px;
}

.module_lb {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
}

.btn_1 {
    width: 154px;
    height: 47px;
    border: 1px solid rgba(255, 156, 0, 1);
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_1 > span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(255, 156, 0, 1);
}

.btn_2 {
    width: 154px;
    height: 47px;
    background: rgba(255, 156, 0, 1);
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_2 > span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
}

.btn_s {
    height: 16px;
}

.module5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.module5 > span:nth-child(1) {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 83px;
}

.module5 > span:nth-child(2) {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 150px;
    width: 60%;
    text-align: center;
}

.bg_bottom {
    background: url(/images/home_bg_bottom.png) top center no-repeat;
}

.alien-hand {
    padding-top: 100px;
    padding-bottom: 50px;
}

.block_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.block_title {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: bold;
    color: rgba(51, 51, 51, 1)
}

.block_content {
    font-size: 18px;
    color: #666666;
    text-align: center;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 40px;
}

.btn_3 {
    width: 150px;
    height: 47px;
    background: rgba(255, 156, 0, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn_3 > span {
    color: #FFFFFF;
}

.btn_3:hover {
    opacity: 0.7;
}

.module6 {
    background: url("/images/home_6.png") top center no-repeat;
}


@media screen and (max-width: 768px){
    .module6 {
        background:#FFFFFF;
    }
    .bg_bottom{
        background:#FFFFFF;
    }
}


.module6 > img {
    width: 80%;
}

.block_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 200px;
    padding-top: 150px;
}

.modal-dialog {
    width: 80%;
}

.carousel-btn {
    background-image: none !important;
}
