.t85002 {
  position: relative;
  .header {
    position: absolute;
    top: 5%;
    left: 18%;
    display: flex;
    flex-direction: column;

    img {
      &:nth-child(1) {
        width: 2.6rem;
        height: 0.53rem;
      }
      &:nth-child(2) {
        height: 0.5rem;
        width: 6.7rem;
        margin-top: 0.32rem;
      }
      &:nth-child(3) {
        height: 0.4rem;
        width: 4.3rem;
        margin-top: 0.32rem;
      }
    }
    span {
      width: 0.33rem;
      height: 0.04rem;
      background-color: #f3981b;
      margin-top: 0.2rem;
    }
    p {
      height: 1.44rem;
      width: 5.49rem;
      color: white;
      margin-top: 0.3rem;
      font-size: 0.18rem;
      font-family: PingFang;
      font-weight: 400;
    }
  }

  section:nth-child(2) {
    position: relative;
    h3 {
      position: absolute;
      color: white;
      left: 50%;
      margin-top: 0.8rem;
      transform: translate(-50%, 0);
      font-size: 0.36rem;
    }
    a {
      height: 0.5rem;
      width: 2.9rem;
      background-color: #ff9c00;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 0.18rem;
      position: absolute;
      bottom: 0.8rem;
      right: 23%;
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
  section:nth-child(3) {
    position: relative;
    div {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translate(0, -50%);
      h3 {
        font-size: 0.36rem;
        color: white;
      }
      p {
        margin-top: 0.3rem;
        color: white;
        font-size: 0.18rem;
        height: 1.2rem;
        width: 4.85rem;
      }
    }
  }

  section:nth-child(4) {
    position: relative;
    div {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 50%;
      transform: translate(-50%,0);
      text-align: center;
      width: 65%;
      h3 {
        color: white;
        margin-top: 0.8rem;
        font-size: 0.36rem;
      }
      .img2{
        margin-top: 0.8rem;
      }
    }
  }
  .other {
    background-color: #eeeeee;
    text-align: center;
    padding-top: 0.6rem;
    span {
      font-size: 0.36rem;
      color: #333;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
