.blocklyDialogContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 10000;
}

.blocklyDialogMask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
}

.blocklyDialog {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 4px;
  color: #4a596f;
  text-align: center;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 400px;
  outline: 0;
}

.blocklyDialogClose {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  text-decoration: none;
  color: rgba(74, 89, 111, 0.2);
  padding: 0;
  width: 26px;
  height: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.blocklyDialogClose i {
  display: inline-block;
  opacity: 0.5;
  width: 12px;
  height: 12px;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTUxODM3Nzg0NjI1IiBjbGFzcz0iaWNvbiIgc3R5bGU9IiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9Ijg4OTQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PGRlZnM+PHN0eWxlIHR5cGU9InRleHQvY3NzIj48L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNzgzLjggODkyLjdMNTEyIDYyMC45IDI0MC4yIDg5Mi43Yy0zMCAzMC03OC43IDMwLTEwOC43IDBzLTMwLTc4LjcgMC0xMDguN2wyNzEuOC0yNzEuOC0yNzEuOS0yNzEuOWMtMzAtMzAtMzAtNzguNyAwLTEwOC43czc4LjctMzAgMTA4LjcgMEw1MTIgNDAzLjRsMjcxLjgtMjcxLjhjMzAtMzAgNzguNy0zMCAxMDguNyAwczMwIDc4LjcgMCAxMDguN0w2MjAuNyA1MTIuMSA4OTIuNiA3ODRjMzAgMzAgMzAgNzguNyAwIDEwOC43cy03OC43IDMwLTEwOC44IDB6IiBwLWlkPSI4ODk1Ij48L3BhdGg+PC9zdmc+') no-repeat;
  background-size: cover;
}

.blocklyDialogClose:hover {
  background-color: rgba(74, 89, 111, 0.2);
  color: #fff;
}

.media-modal .mediaModal-contain {
  width: 960px;
  height: 540px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -270px 0 0 -480px;
  display: none;
}