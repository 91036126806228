.mine >.carousel-indicators{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mine >.carousel-indicators li{
    width:163px;
    height:39px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-indent:0;
    border: none;

}
.mine >.carousel-indicators li.active{
    width:163px;
    height:40px;
    background:#E49316;
    border-radius: 20px;
}

.mine >.carousel-indicators li.active>span{
    color: #FFFFFF;
}
.carouse {
    width: 100%;
}

.carouse > div {
    height: 60rem;
}

