.footer-ul {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  li {
    margin: 0px;
  }
  .message-board {
    background: #fe9d00;
    border: none;
    margin-left: 5px;
    width: 100%;
    &:hover, &:focus{
      background-color: #fe9d00;
    }
    & span {
      color: white;
      font-size: 14px;
    }
  }
}