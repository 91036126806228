.Support {
    background-color: #f0f1f2;
}


/*锚点链接重定位*/
.common-problem-container #common-problem,
.software-download-container #software-download,
.document-download-container #document-download,
.document-download-container #common-document,
.document-type #product-document,
.document-type #product-83001,
.document-type #product-83002,
.document-type #product-83003{
    position: relative;
    top: -100px;
    display: block;
    height: 2px;
    overflow: hidden;

}
/*#common-problem,#software-download,#document-download,#common-document,#product-document,#83001,#83002,#83003 {*/
    /*position: relative;*/
    /*top: -150px;*/
    /*display: block;*/
    /*height: 0;*/
    /*overflow: hidden;*/
/*}*/
.support-container {
    width: 1200px ;
    margin-top: 60px ;
    padding: 0 ;
}
.support-left {
    width: 335px;
}
.support-right {
    width: 845px;
    padding: 30px;
}
.support-head-title {
    height: 300px;
}

@media screen and (max-device-width: 768px){
    .support-left {
        width: 30%;
    }
    .support-right {
        width: 70%;
        padding: 30px;
    }
    .support-container {
        width: 100% ;
        margin-top: 60px ;
        padding: 0 ;
    }

}
.support-left,.support-right {
    background-color: #ffffff;
}
.support-list1 {
    margin-top: 33px;
}
.support-list1>li>a {
    padding-left: 27px;
}
.support-list2>li>a {
    padding-left: 47px;
}
.support-list3>li>a {
    padding-left: 67px;
}
.support-list1>li>a,.support-list2>li>a,.support-list3>li>a {
    line-height: 40px;
    margin-bottom: 0;
    font-size: 16px;
    display: block;
    color: #000000;
}
.support-list1>li>a:hover,.support-list2>li>a:hover,.support-list3>li>a:hover {
    background-color: #f7f9f9;
    border-right: #ff9b00 solid 4px;
}

.software-download-container {
    margin-bottom: 20px;
}

.common-problem-container>h2,.software-download-container>h2,.document-download-container>h2 {
    font-size: 24px;
    margin-top: 0;
}
.common-problem-container>h2>img,.software-download-container>h2>img,.document-download-container>h2>img {
    width: 35px;
    height: 35px;
    margin-right: 20px;
}
.common-problem {
    padding: 20px;
    background-color: #f7f9f9;
    margin-bottom: 20px;
}

.software-download {
    width: 382px;
    background-color: #f7f9f9;
    padding: 20px;
}
.Support p {
    margin-bottom: 0;
}
.footerpage p {
    margin: 0 0 10px!important;
}
ol,ul {
    margin-bottom: 0;
}

.question>p {
    font-size: 18px;
}
.question>p>img {
    width: 30px;
    height: 30px;
    margin-left: -11px;
    margin-bottom: 4px;
}
.answer>li>p {
    color: #727272;
    font-size: 16px;
    line-height: 27px;
    padding: 0;
}
.software-icon>img {
    width: 60px;
    height: 60px;
}
.software-download>.pull-right {
    width: 260px;
}
.software-download .software-name {
    font-size: 18px;
    margin: 0;
}
.software-download .software-date {
    color: #999999;
    margin-bottom: 10px;
}
.software-download .software-link>a {
    color: #63a1fe;
}
.software-download .software-link>a:first-child {
    margin-right: 40px;
}
.software-link>a {
    cursor: pointer;
    font-size: 14px;
    position: relative;
}
/*二维码*/
.software-link>a>img {
    position: absolute;
    width: 160px;
    height: 160px;
    top: 8px;
    left: -48px;
    font-size: 24px;
    line-height: 100px;
    display: none;
}
.software-link>a:hover img {
    display: block;
}

.document-type {
    margin-top: 20px;
}
.document-type>h3 {
    font-size: 20px;
    margin-top: 0;
}
.document-file {
    background-color: #f7f9f9;
    padding: 0 25px;
    margin-top: 20px;
}
.document-file>p {
    font-size: 16px;
    line-height: 16px;
}
.document-file>p>img {
    width: 30px;
    height: 30px;
    margin: 0 5px 4px -11px;
}
.document-file>p>a {
    color: #63a1fe;
    font-size: 14px;
    line-height: 35px;
}

.support-head,.support-head-title {
    margin: 0 auto;
}
.support-head {
    /*background:linear-gradient(50deg,#e1ddd8 0%,#e1ddd8 30%,transparent 40%,transparent 60%,#e1ddd8 70%,#e1ddd8 100%),*/
    /*url(../img/support-head.png) no-repeat center center;*/
    background-image: url(/uploads/img/support-head.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: auto 300px;
}

.support-head-title>p {
    font-size: 40px;padding-top: 170px;
    padding-left: 100px;
}


